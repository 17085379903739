import { NavItem } from './nav-item/nav-item';

export const adminItem: NavItem[] = [
  {
    navCap: 'Home',
  },
  {
    displayName: 'Dashboard',
    iconName: 'solar:widget-add-line-duotone',
    route: '/dashboard',
    roles:[ 'Admin', 'Store'], 
  },
  {
    displayName: 'Leads',
    iconName: 'solar:user-circle-line-duotone',
    roles:[ 'Admin','Store'], 
    sub_menu:[
      {
        displayName: 'Upload-File',
        iconName: 'solar:cloud-upload-line-duotone',
        route: '/upload-file',
        roles:[ 'Admin','Store'], 
      },
      {
        displayName: 'Active',
        iconName: 'solar:clipboard-check-line-duotone',
        route: '/leads',
        roles:[ 'Admin','Store'], 
      },
      {
        displayName: 'Domain Restricted',
        iconName: 'fa-exclamation-circle',
        route: '/domain-mismatch',
        roles:[ 'Admin', 'Store'], 
      },
      {
        displayName: 'Admin',
        iconName: 'fa-cogs',
        route: '/admin-leads',
        roles:[ 'Admin', 'Store'], 
      }
    ]
  },
  {
    displayName: 'Leads Type',
    iconName: 'solar:document-line-duotone',
    route: '/lead-type',
    roles:[ 'Admin', 'Store'], 
  },
  {
    displayName: 'Leaves',
    iconName: 'solar:clipboard-line-duotone',
    route: '/leaves',
    roles:[ 'Admin', 'Store'], 
  },
  {
    displayName: 'Holiday Calendar',
    iconName: 'solar:calendar-line-duotone',
    route: '/holiday-calendar',
    roles:[ 'Admin', 'Store'], 
  },
  {
    displayName: 'Domain Restricted',
    iconName: 'solar:letter-line-duotone',
    route: '/domain-email',
    roles:[ 'Admin', 'Store'], 
  },
  {
    displayName: 'Stores',
    iconName: 'solar:shop-line-duotone',
    route: '/stores',
    roles:[ 'Admin', 'Store'], 
  },
  {
    displayName: 'Representative',
    iconName: 'solar:user-plus-line-duotone',
    route: '/sales-representative',
    roles: [ 'Admin', 'Store'], 
  },

];

export const storeItem: NavItem[] = [
  {
    navCap: 'Home',
  },
  {
    displayName: 'Leads',
    iconName: 'solar:user-circle-line-duotone',
    roles:[ 'Admin','Store'], 
    sub_menu:[
      {
        displayName: 'Active',
        iconName: 'solar:clipboard-check-line-duotone',
        route: '/leads',
        roles:[ 'Admin','Store'], 
      }
    ]
  },
  {
    displayName: 'Representative',
    iconName: 'solar:user-plus-line-duotone',
    route: '/sales-representative',
    roles: [ 'Admin', 'Store'], 
  },

];