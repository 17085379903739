import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AlertService } from "../common/alert.service";
import { Router } from "@angular/router";
import { catchError, Observable, throwError } from "rxjs";
import { MessageService } from "../message-service/message.service";
import { AuthService } from "../auth/auth.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private readonly authService:AuthService,
    private readonly _alertService: AlertService,
    private readonly _router: Router
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(error => this.handeError(error))
      
    );
  }

  private handeError(error: any) {
    if (error.status == 401) {
      this.authService.logout()      
      this._alertService.error("Token Expired");
    }
    else if (error.status == 500) {
      this._alertService.error(error.error.message || 'Something went wrong', 'OK');
    }
    else if (error.status == 0) {
      this._alertService.error("Unknown Error, Please check the API is in running stage.");
    }
    else if (error.status != 0 && error.url.includes("login") == false) {
      this._alertService.error(error.error.message || 'Something went wrong', 'OK');
    }

    return throwError(() => error);
  }

}