<div *ngIf="item.navCap">
  <ng-container *ngIf="item.divider">
    <span class="sidebar-divider"></span>
  </ng-container>

  <div mat-subheader class="nav-caption">
    {{ item.navCap }}
  </div>
</div>

<ng-container *ngIf="!item.navCap">
  <!-- Main item (Clickable) -->
  <a mat-list-item (click)="onItemSelected(item)" [ngClass]="{
    'mat-toolbar mat-primary activeMenu': isActive(item)
  }" class="menu-list-item">
    <span class="iconify routeIcon" matListItemIcon [attr.data-icon]="item.iconName"></span>
    <span class="hide-menu" (click)="toggleSubmenu(item)">{{ item.displayName }}</span>
    
    <!-- If item has a submenu, show a toggle icon -->
    <span *ngIf="item.sub_menu && item.sub_menu.length > 0" class="expand-icon" 
      (click)="toggleSubmenu(item)">
      <mat-icon>{{ isSubmenuOpen(item) ? 'expand_less' : 'expand_more' }}</mat-icon>
    </span>
  </a>

  <!-- Submenu (Visible if submenuOpen is true) -->
  <div *ngIf="isSubmenuOpen(item)">
    <ul class="submenu">
      <li *ngFor="let subItem of item.sub_menu">
        <a mat-list-item (click)="onItemSelected(subItem)" [ngClass]="{
          'mat-toolbar mat-primary activeMenu': isActive(subItem)
        }" class="menu-list-item">
          <span class="iconify routeIcon" matListItemIcon [attr.data-icon]="subItem.iconName"></span>
          <span class="hide-menu">{{ subItem.displayName }}</span>
          <!-- Active {
            "displayName": "Active",
            "iconName": "solar:clipboard-check-line-duotone",
            "route": "/leads",
            "roles": [
              "Admin",
              "Store"
            ]
          } -->
        </a>
      </li>
    </ul>
  </div>
</ng-container>
