import { Injectable } from '@angular/core';
import { environment } from 'src/environment/environment';

@Injectable({ providedIn: 'root' })
export class ApiConfigService {

  private static BASE_URL = environment.API_BASE_URL;
  // private static BASE_URL = 'http://192.168.6.59:2000/api/v1/'

  private readonly headers = {
    'Content-Type': 'application/json',
  };

  public static endpoints = {
    login: `${ApiConfigService.BASE_URL}auth/login`,
    updateProfile: `${ApiConfigService.BASE_URL}users/update`,
    changePassword: `${ApiConfigService.BASE_URL}auth/change-password`,
    getNotifications: `${ApiConfigService.BASE_URL}notification`,
    readNotifications: `${ApiConfigService.BASE_URL}notification/read`,
    register: `${ApiConfigService.BASE_URL}auth/register`,
    verifyOtp: `${ApiConfigService.BASE_URL}auth/verify-otp`,
    forgotPassword: `${ApiConfigService.BASE_URL}auth/forgot-password`,
    resetPasswordOtpVerify: `${ApiConfigService.BASE_URL}auth/reset-password`,
    getDashboardDataApi: `${ApiConfigService.BASE_URL}dashboard`,
    getLeadStatusApi: `${ApiConfigService.BASE_URL}dashboard/lead-status`,
    getLeadReportApi: `${ApiConfigService.BASE_URL}dashboard/lead-report`,
    getStoresByDistrictApi: `${ApiConfigService.BASE_URL}/dashboard/stores`,
    getConversionRateApi: `${ApiConfigService.BASE_URL}/dashboard/conversion-rate`,
    getMomYoyReportApi: `${ApiConfigService.BASE_URL}/dashboard/analysis-report`,
    uploadSheetApi: `${ApiConfigService.BASE_URL}leads/upload-excel`,
    exportLeadsApi: `${ApiConfigService.BASE_URL}export/leads`,
    downloadSheetApi: `${ApiConfigService.BASE_URL}export/get-sheet`,
    generateStatusApi: `${ApiConfigService.BASE_URL}export/status`,
    updateLeadStatusApi: `${ApiConfigService.BASE_URL}leads/status`,
    leadListApi: `${ApiConfigService.BASE_URL}leads`,
    leadsDetails: `${ApiConfigService.BASE_URL}leads/leadsDetails`,
    leadViewPdfApi: `${ApiConfigService.BASE_URL}leads/viewPdf`,
    applyLeadsTypeListApi: `${ApiConfigService.BASE_URL}lead-type`,
    getLeadsTypeListApi: `${ApiConfigService.BASE_URL}lead-type/lists`,
    updateLeadsTypeListApi: `${ApiConfigService.BASE_URL}lead-type/update`,
    deleteLeadsTypeListApi: `${ApiConfigService.BASE_URL}lead-type/delete`,
    leaveListApi: `${ApiConfigService.BASE_URL}leaves`,
    getLeavesApi: `${ApiConfigService.BASE_URL}leaves/leavesbyid`,
    updateLeavesApi: `${ApiConfigService.BASE_URL}leaves/update`,
    applyLeavesApi: `${ApiConfigService.BASE_URL}leaves/apply`,
    cancelLeavesApi: `${ApiConfigService.BASE_URL}leaves/cancel`,
    deleteCancelledLeavesApi: `${ApiConfigService.BASE_URL}leaves/delete`,
    getHolidayListApi: `${ApiConfigService.BASE_URL}holidays/lists`,
    getHolidayDataApi: `${ApiConfigService.BASE_URL}holidays/listById`,
    createHolidayApi: `${ApiConfigService.BASE_URL}holidays`,
    updateHolidayApi: `${ApiConfigService.BASE_URL}holidays/update`,
    deleteHolidayApi: `${ApiConfigService.BASE_URL}holidays/delete`,
    emailDomainListApi: `${ApiConfigService.BASE_URL}email-domain-list`,
    applyDomainApi: `${ApiConfigService.BASE_URL}domains`,
    getDomainListApi: `${ApiConfigService.BASE_URL}domains/lists`,
    getDomainDataApi: `${ApiConfigService.BASE_URL}domains/listById`,
    updateDomainApi: `${ApiConfigService.BASE_URL}domains/update`,
    deleteDomainApi: `${ApiConfigService.BASE_URL}domains/delete`,
    representatives: `${ApiConfigService.BASE_URL}leaves/representatives`,
    exportStoreApi: `${ApiConfigService.BASE_URL}export/stores`,
    getStoresListApi: `${ApiConfigService.BASE_URL}store/lists`,
    getStoresDataApi: `${ApiConfigService.BASE_URL}store/listsById`,
    createStoresApi: `${ApiConfigService.BASE_URL}store`,
    updateStoreApi: `${ApiConfigService.BASE_URL}store/update`,
    deleteStoreApi: `${ApiConfigService.BASE_URL}store/delete`,
    verifyStoreApi: `${ApiConfigService.BASE_URL}store/verify`,
    getAssignmentListApi: `${ApiConfigService.BASE_URL}store/representatives`,
    createAssignmentApi: `${ApiConfigService.BASE_URL}assignment`,
    leadReAssignmentApi: `${ApiConfigService.BASE_URL}assignment/reAssign`,
    exportRepsApi: `${ApiConfigService.BASE_URL}export/reps`,
    getRepresentativeListApi: `${ApiConfigService.BASE_URL}representative/lists`,
    getRepresentativeDataApi: `${ApiConfigService.BASE_URL}representative/listById`,
    createRepresentativeApi: `${ApiConfigService.BASE_URL}representative`,
    updateRepresentativeApi: `${ApiConfigService.BASE_URL}representative/update`,
    deleteRepresentativeApi: `${ApiConfigService.BASE_URL}representative/delete`,
    representativeDetails: `${ApiConfigService.BASE_URL}representative/representativeDetails`,
  };

  public getHeaders(): { [key: string]: string } {
    return this.headers;
  }

  public getEndpoint(key: keyof typeof ApiConfigService.endpoints): string {
    return ApiConfigService.endpoints[key];
  }
}
