import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { MaterialModule } from 'src/app/material.module';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatButtonModule } from '@angular/material/button';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FeatherIconsComponent } from "../../../shared/feather-icons/feather-icons.component";
import { ProfileService } from 'src/app/services/profile-service/profile-service.service';
import { AlertService } from 'src/app/services/common/alert.service';
import { MessageService } from 'src/app/services/message-service/message.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterModule, CommonModule, NgScrollbarModule, MaterialModule, MatButtonModule, FeatherIconsComponent],
  templateUrl: './header.component.html',
  encapsulation: ViewEncapsulation.None,
})

export class HeaderComponent {
  @Input() showToggle = true;
  @Input() toggleChecked = false;
  @Output() toggleMobileNav = new EventEmitter<void>();
  @Output() toggleCollapsed = new EventEmitter<void>();
  isLoading: boolean = false;
  notifications: any[] = [];
  responseData: any;
  notifications_id: any;
  unReadNotification: any

  constructor(
    public authService: AuthService,
    private router: Router,
    private profileService: ProfileService,
    private messageService: MessageService,
    private _alertService: AlertService
  ) { }

  ngOnInit() {
    this.getNotifications();
    this.messageService.unreadCount$.subscribe(count => {
      this.unReadNotification = count;
    });
  }

  getNotifications(requestData: any = "") {
    this.isLoading = true;
    this.profileService.getNotifications(requestData).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        if (response.status === 'success' && response.data != null)
        this.responseData = response?.data
        this.unReadNotification = response?.data?.unRead
        this.notifications = response?.data?.notification
        this.notifications_id = this.notifications.map((n: any) => n._id);
        // Send unread count to MessageService
        this.messageService.updateUnreadCount(this.unReadNotification);
      },
      error: (error: any) => {
        this.isLoading = false;
        this._alertService.error(error.error.message || 'Something went wrong', 'OK');
      }
    })
  }

  handleNotificationClick(notification: any) {
    if (!notification.isRead) {
      this.readNotification(notification); 
    } else {
      this.router.navigate(['/admin-leads']);
    }
  }
  readNotification(notification: any) {
    if (notification.isRead) {
      return;
    }
    const requestModel = { id: notification._id };

    this.profileService.readNotifications(requestModel).subscribe({
      next: (response: any) => {
        if (response.status === 'success') {
          notification.isRead = true; 
        
        // Decrease the unread notification count in real-time
        if (this.unReadNotification > 0) {
          this.unReadNotification--;
        }
        } else {
          this._alertService.error('Failed to update notification', 'OK');
        }
      },
      error: (error: any) => {
        this._alertService.error(error.error.message || 'Something went wrong', 'OK');
      }
    });
  }

  onAccountClick() {
    this.router.navigate(['/profile']);
  }

  onChangePasswordClick() {
    this.router.navigate(['/change-password']);
  }

  onNotificationClick() {
    this.router.navigate(['/notifications']);
  }
}
