import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageService } from '../message-service/message.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  authDataSubscription: Subscription;
  isAuthenticated: any = '';
  constructor(private dataService: MessageService, private http: HttpClient) {
    this.authDataSubscription = this.dataService.authDate$.subscribe((data) => {
      if (data && Object.keys(data)) {
        this.isAuthenticated = data.token;
      }
    });
    this.isAuthenticated = !!localStorage.getItem('auth_token')
      ? localStorage.getItem('auth_token')
      : false;
  }
  postApi(api: string, body: any, isUpload?: boolean) {
    let headers = new HttpHeaders({
      // 'Content-Type': 'application/json',
      Authorization: `Bearer ${this.isAuthenticated}`, // Attach the Bearer token in the Authorization header
    });

     // Don't set 'Content-Type' manually for FormData
  if (!isUpload) {
    headers = headers.set('Content-Type', 'application/json');
  }
    return this.http.post(api, body, { headers });
  }
  getApi(api: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.isAuthenticated}`, // Attach the Bearer token in the Authorization header
    });
    return this.http.get(api, { headers });
  }
  deleteApi(api: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.isAuthenticated}`, // Attach the Bearer token in the Authorization header
    });
    return this.http.delete(api, { headers });
  }

  updateApi(api: string, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.isAuthenticated}`, // Attach the Bearer token in the Authorization header
    });
    return this.http.put(api, body, { headers });
  }
}
