import {
  Component,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { NavItem } from './nav-item';
import { Router } from '@angular/router';
import { NavService } from '../../../../services/common/nav.service';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/material.module';
import { CommonModule } from '@angular/common';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-nav-item',
  standalone: true,
  imports: [TranslateModule, MaterialModule, CommonModule],
  templateUrl: './nav-item.component.html',
  styleUrls: [],
})
export class AppNavItemComponent implements OnChanges, OnInit {
  @Output() toggleMobileLink: any = new EventEmitter<void>();
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();
  userRole: any = '';
  @Input() item: NavItem | any;
  @Input() depth: any;
  selectedRoute: string = '';
  submenuOpen: Map<string, boolean> = new Map();

  constructor(public navService: NavService, public router: Router, public authService: AuthService) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit() {
    // this.selectedRoute = localStorage.getItem('selectedRoute') || '';
    const storedSubmenuStates = localStorage.getItem('submenuStates');
    if (storedSubmenuStates) {
      this.submenuOpen = new Map(JSON.parse(storedSubmenuStates));
    } 

    // if (this.selectedRoute) {
    //   this.router.navigate([this.selectedRoute]);
    // }
  }

  ngOnChanges() {
    this.navService.currentUrl.subscribe((url: string) => {
      this.selectedRoute = url;
    });
  }

  isSelected(item: NavItem): boolean {
    return this.selectedRoute === item.route;
  }

  onItemSelected(item: NavItem) {
    if (item.route) {
      localStorage.setItem('selectedRoute', item.route);
      this.selectedRoute = item.route;
      this.router.navigate([item.route]);
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } else {
      console.error('Route is undefined for the selected item');
    }
  }


  // isActive(item: NavItem): boolean {
  //   const currentRoute = localStorage.getItem('selectedRoute');
  //   return item.route ? this.router.isActive(item.route, true) : false;
  // }

  isActive(item: NavItem): boolean {
    const currentRoute = this.router.url; // Get the full current route
    return item.route ? currentRoute.startsWith(item.route) : false;
  }
  

  toggleSubmenu(item: NavItem) {
    if (item.displayName) {
      const currentState = this.submenuOpen.get(item.displayName) ?? false;
      const newState = !currentState;
      this.submenuOpen.set(item.displayName, newState);

      localStorage.setItem('submenuStates', JSON.stringify(Array.from(this.submenuOpen.entries())));
    }
  }

  isSubmenuOpen(item: NavItem): boolean {
    return item.displayName ? this.submenuOpen.get(item.displayName) ?? false : false;
  }
}




