import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MessageService } from '../message-service/message.service';
import { AlertService } from '../common/alert.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private isAuthenticated = false;
  auth_data: any = {}
  authDataSubscription: Subscription
  authRole: any = ''
  constructor(
    private dataService: MessageService,
    private router: Router,
    private alertService: AlertService
  ) {
    this.authDataSubscription = this.dataService.authDate$.subscribe(data => {
      if (data && Object.keys(data)) {
        this.authRole = data?.user?.role?.name
        this.isAuthenticated = data.token;
      }
    })
    this.isAuthenticated = !!localStorage.getItem('auth_token');
    if (localStorage.getItem('auth_role')) {
      let auth_role: any = localStorage.getItem('auth_role')
      this.authRole =  JSON.parse(auth_role).name;
    }
  }

  getUserRole(): string {
    let auth_role: any = localStorage.getItem('auth_role')
    this.authRole =  JSON.parse(auth_role).name;
    return this.authRole
  }

  IsAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  logout(): void {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('auth_role');
    this.router.navigate(["/authentication/login"])
    this.isAuthenticated = false;
    this.alertService.success("Logout Successfully")
  }

  ngOnDestroy() {
    if (this.authDataSubscription && this.authDataSubscription != null) {
      this.authDataSubscription.unsubscribe();
    }
  }
}