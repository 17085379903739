import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfigService } from './apiConfig.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private isAuthenticated = false;
  authDataSubscription: Subscription
  authData:any={}
  constructor(
    private http: HttpClient,
    private dataService: MessageService,
    private router: Router
  ) { 
    this.authDataSubscription = this.dataService.authDate$.subscribe(data => {
      if (data && Object.keys(data)) {
        this.isAuthenticated = data.token;
      }
    })
    this.isAuthenticated = !!localStorage.getItem('auth_token');
  }
  


  IsAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  logout(): void {
    localStorage.removeItem('auth_token');
    this.router.navigate(["/authentication/login"])
    this.isAuthenticated = false;
  }
  ngOnDestroy() {
    if (this.authDataSubscription && this.authDataSubscription != null) {
      this.authDataSubscription.unsubscribe();
    }
  }
}