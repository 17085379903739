import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const requiredRoles = route.data['roles'] as Array<string>; // Fetch roles from route data
    const userRole = this.authService.getUserRole(); // Assume this method fetches the current user's role
    if (requiredRoles?.includes(userRole)) {
      return true;
    } else {
      this.router.navigate(['/leads']); // Redirect if unauthorized
      return false;
    }
  }
}
