import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private authDataSubject = new BehaviorSubject<any>(null);  // Initially null
  authDate$ = this.authDataSubject.asObservable();  // Observable to allow components to subscribe
  private emailDataSubject = new BehaviorSubject<any>(null);  // Initially null
  emailDate$ = this.emailDataSubject.asObservable(); 
  private unreadCountSource = new BehaviorSubject<number>(0);
  unreadCount$ = this.unreadCountSource.asObservable(); // Observable to allow components to subscribe
  constructor() {

  }
  setAuthData(data: string): void { // or any other data type
    this.authDataSubject.next(data);
  }
  setEmailData(data: string): void { // or any other data type
    this.emailDataSubject.next(data);
  }

  updateUnreadCount(count: number) {
    this.unreadCountSource.next(count);
  }

  convertObjToQueryString(queryObj:any = {}) {
    let query = ''
    if (Object.keys(queryObj).length > 0) {
      query = new URLSearchParams(queryObj).toString();
    }
    return query;
  }
}
