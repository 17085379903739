import { HttpService } from '../http-service/http.service';
import { Injectable } from '@angular/core';
import { ApiConfigService } from '../common/apiConfig.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpService) { }
  updateProfile(requestModel: any) {
    return this.http.postApi(ApiConfigService.endpoints.updateProfile, requestModel);
  }

  changePassword(requestModel: any) {
    return this.http.postApi(ApiConfigService.endpoints.changePassword, requestModel);
  }

  getNotifications(requestModel: any) {
    return this.http.postApi(ApiConfigService.endpoints.getNotifications, requestModel);
  }

  readNotifications(requestModel: any) {
    return this.http.postApi(ApiConfigService.endpoints.readNotifications, requestModel);
  }
  
}
