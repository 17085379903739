import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, createUrlTreeFromSnapshot } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";

export const AuthGuard = (next: ActivatedRouteSnapshot) => {

  let authService = inject(AuthService);
  
  if (authService.IsAuthenticated()) {
    return true;
  }
  else {
    return createUrlTreeFromSnapshot(next, ['/authentication/login']);
  }
}