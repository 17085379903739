import { Routes } from '@angular/router';
import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { AuthGuard } from './guards/auth.guard';
import { canDeactivateGuard } from './guards/can-deactivate.guard';

export const routes: Routes = [
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: '',
        redirectTo: '/authentication/login',
        pathMatch: 'full',
      },
      {
        path: 'authentication',
        loadChildren: () =>
          import('./pages/authentication/authentication.routes').then(
            (m) => m.AuthenticationRoutes
          ),
          canActivate:[canDeactivateGuard]
      },
    ],
  },
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./pages/pages.routes').then((m) => m.PagesRoutes),
        canActivate: [AuthGuard],
      },
      {
        path: 'leads',  
        loadChildren: () =>
          import('./components/leads/leads.routes').then((m) => m.LeadsRoutes),
        canActivate: [AuthGuard],
      },
      {
        path: 'leaves',  
        loadChildren: () =>
          import('./components/leaves/leaves.routes').then((m) => m.LeavesRoutes),
        canActivate: [AuthGuard],
      },
    ],
  },
 
  {
    path: '**',
    redirectTo: '/dashboard',
  },
];
