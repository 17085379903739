import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-branding',
  standalone: true,
  imports: [RouterModule],
  template: `
   <div class="branding">
  <a [routerLink]="userRole === 'Admin' ? '/dashboard' : (userRole === 'Store' ? '/leads' : '/default')">
    <img src="/assets/images/logos/logo-2.png" width="190">
  </a>
</div>

  `,
})
export class BrandingComponent {
  userRole = this.authService.getUserRole();
  constructor( private authService: AuthService) {}
}
