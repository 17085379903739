import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public IsLoading: Subject<boolean>;

  public constructor() {
    this.IsLoading = new Subject<boolean>();
  }

  public show() {
    this.IsLoading.next(true);
  }

  public hide() {    
    this.IsLoading.next(false);
  }
}