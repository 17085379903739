import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private authDataSubject = new BehaviorSubject<any>(null);  // Initially null
  authDate$ = this.authDataSubject.asObservable();  // Observable to allow components to subscribe
  private emailDataSubject = new BehaviorSubject<any>(null);  // Initially null
  emailDate$ = this.emailDataSubject.asObservable();  // Observable to allow components to subscribe
  constructor() {

  }
  setAuthData(data: string): void { // or any other data type
    this.authDataSubject.next(data);
  }
  setEmailData(data: string): void { // or any other data type
    this.emailDataSubject.next(data);
  }

}
