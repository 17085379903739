<mat-toolbar class="topbar">
  <!-- Mobile Menu -->
  @if(!showToggle) {
  }
  <button mat-mini-fab color="inherit" (click)="toggleMobileNav.emit()" class="d-flex justify-content-center">
    <i class="iconify icon-32 d-flex" data-icon="solar:hamburger-menu-line-duotone"></i>
  </button>

  <span class="flex-1-auto"></span>


  <!-- --------------------------------------------------------------- -->
  <!-- Notifications  -->
  <!-- --------------------------------------------------------------- -->


  <button mat-fab extended color="inherit" aria-label="Notifications" [matMenuTriggerFor]="notificationsmenu"
    class="d-flex justify-content-center profile-btn-dd">
    <app-feather-icons [icon]="'bell'" [class]="'tbl-fav-bell'"></app-feather-icons>
    <div>
      <span matBadge="{{unReadNotification}}" matBadgeColor="primary" class="notification-badge"></span>
    </div>
  </button>
  <mat-menu #notificationsmenu="matMenu" class="topbar-dd cardWithShadow">
    <div class="notification-list">
      <div *ngFor="let notification of notifications.slice(0, 10)" 
          [ngClass]="{ 'unread-notification': !notification.isRead }"
          (click)="handleNotificationClick(notification)" 
          class="m-b-10">
        <button mat-menu-item>
          <span>{{ notification?.message }}</span>
        </button>
      </div>
    </div>
  
    <mat-divider></mat-divider>
  
    <!-- View All Notifications Button -->
    <button mat-menu-item (click)="onNotificationClick()">
      <mat-icon class="d-flex align-items-center">
        <app-feather-icons class="d-flex align-items-center"
            [icon]="'chevron-down'" [class]="'tbl-fav-back'">
        </app-feather-icons>
      </mat-icon>
      <span>View all notifications</span>
    </button>
  </mat-menu>
  

  <!-- --------------------------------------------------------------- -->
  <!-- profile Dropdown -->
  <!-- --------------------------------------------------------------- -->

  <button mat-fab extended color="inherit" [matMenuTriggerFor]="profilemenu" aria-label="Notifications"
    class="d-flex justify-content-center profile-btn-dd">
    <img src="/assets/images/profile/user-1.jpg" class="rounded-circle object-cover icon-35 profile-dd" width="35" />
  </button>
  <mat-menu #profilemenu="matMenu" class="topbar-dd cardWithShadow">
    <button mat-menu-item (click)="onAccountClick()">
      <mat-icon class="d-flex align-items-center"><i data-icon="solar:user-id-line-duotone" class="iconify icon-22"></i>
      </mat-icon>My Account
    </button>
    <button mat-menu-item (click)="onChangePasswordClick()">
      <mat-icon class="d-flex align-items-center"><i data-icon="solar:checklist-minimalistic-line-duotone"
          class="iconify icon-22"></i> </mat-icon>Change Password
    </button>
    <div class="p-x-12 m-t-12">
      <a (click)="authService.logout()" mat-stroked-button color="primary" class="w-100">Logout</a>
    </div>
  </mat-menu>

</mat-toolbar>