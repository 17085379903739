<!-- ============================================================== -->
<!-- Only router without any element -->
<!-- ============================================================== -->
<mat-sidenav-container
  class="blue_theme light-theme"
  autosize
  autoFocus
  dir="ltr"
>
  <router-outlet></router-outlet>
</mat-sidenav-container>
