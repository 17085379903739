import { ChangeDetectorRef, Component } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterOutlet } from '@angular/router';
import { LoaderService } from './services/loader.service';
import { Subject, Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, MatProgressSpinnerModule, CommonModule],
  templateUrl: './app.component.html',
})
export class AppComponent {

  title = 'Tricoast Lead Automation';
  public isLoading: boolean;
  LoaderSubscription: Subscription
  constructor(private readonly _loaderService: LoaderService,
    private cdref: ChangeDetectorRef
  ) {
    this.LoaderSubscription = this._loaderService.IsLoading.subscribe(x => this.isLoading = x)
  }
  ngAfterContentChecked() {
    this.cdref.detectChanges();
 }

  ngOnDestroy() {

    if (this.LoaderSubscription && this.LoaderSubscription != null) {
      this.LoaderSubscription.unsubscribe();
    }
  }
}
