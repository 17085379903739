import { HttpEvent, HttpHandler, HttpInterceptor, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { LoaderService } from './loader.service';
import { finalize, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()

export class LoaderInterceptor implements HttpInterceptor {
  private activeRequests = 0;

  public constructor(
    private readonly _loaderService: LoaderService
  ) {

    
   }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.activeRequests == 0) {
      this._loaderService.show();
    }

    this.activeRequests++;
    return next.handle(req).pipe(
      finalize(() => {
        this.stopLoader();
      }),
    );
  }

  private stopLoader() {
    this.activeRequests--;
    if (this.activeRequests == 0) {
      this._loaderService.hide();
    }
  }
}